import { useEffect, useRef, useState } from "react";
import useClickOutside from "../../hooks/useClickOutside";
import { createPortal } from "react-dom";
import useInView from "../../hooks/useInView";

const Dropdown = ({
  children,
  setShow,
  show,
  fullWidth = false,
  hasMinWidth = true,
  leftOffset = 0,
  top = 6,
  centerTooltip,
  styles = {},
}) => {
  const [style, setStyle] = useState({});
  const ref = useRef(null);
  const tooltipRef = useRef(null);

  useClickOutside(tooltipRef, (e) => {
    if (e.target.id === "overlay" || !setShow) return;
    setShow(false);
  });

  useEffect(() => {
    if (!show) return;
    const closeTooltip = () => {
      if (!setShow) return;
      setShow(false);
    };

    const root = document.getElementById("root");
    root.addEventListener("scroll", closeTooltip, true);
    return () => {
      root.removeEventListener("scroll", closeTooltip, true);
    };
  }, [show]);
  //
  const left = 0;
  const showTooltip = (e) => {
    if (!ref.current) return;

    const width =
      (tooltipRef.current.clientWidth >= window.innerWidth
        ? window.innerWidth - 8
        : tooltipRef.current.clientWidth) ?? 200;

    const targetWidth = e.currentTarget.offsetWidth;
    // some maths to align the tooltip with whatever you just hovered over (the 'target');
    // this style object will be passed as the tooltip's 'style' prop;
    const style = {
      maxWidth: window.innerWidth - 8,
    };
    if (hasMinWidth) {
      style.minWidth = width;
    }

    const dimensions = ref.current.getBoundingClientRect(); // where on the screen is the target
    // center align the tooltip by taking both the target and tooltip widths into account
    style.left =
      dimensions.left - left - (width * leftOffset - targetWidth * leftOffset);

    if (centerTooltip) {
      style.left =
        style.left - tooltipRef.current.clientWidth / 2 + targetWidth / 2;
    }

    style.left = Math.max(left, style.left); // make sure it doesn't poke off the left side of the page
    style.left = Math.min(style.left, document.body.clientWidth - width - left); // or off the right
    if (style.left === document.body.clientWidth - width - left) {
      style.left = style.left - 6;
    }

    if (
      dimensions.top +
        top +
        dimensions.height +
        (tooltipRef.current?.clientHeight ?? 0) <
      window.innerHeight
    ) {
      // the top half of the page
      // when on the top half of the page, position the top of the tooltip just below the target (it will stretch downwards)
      style.top = dimensions.top + dimensions.height + top;
    } else {
      // when on the bottom half, set the bottom of the tooltip just above the top of the target (it will stretch upwards)
      style.bottom = window.innerHeight - dimensions.top + top;
    }

    if (fullWidth) {
      style.width = ref.current.offsetWidth;
    }

    setStyle(style);
    if (setShow) {
      setShow((prev) => !prev);
    }
  };

  return (
    <>
      <div ref={ref} onClick={showTooltip} style={{ position: "relative" }}>
        {show && (
          <div
            id="overlay"
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              top: 0,
              left: 0,
              zIndex: 2,
              cursor: "pointer",
            }}
          />
        )}
        {children[0]}
      </div>
      {createPortal(
        <div
          ref={tooltipRef}
          style={{
            position: "fixed",
            opacity: show ? 1 : 0,
            pointerEvents: show ? "all" : "none",
            transition: "opacity 0.3s",
            zIndex: 13,
            ...style,
            ...styles,
          }}
        >
          {children[1]}
        </div>,
        document.querySelector("#portal")
      )}
    </>
  );
};

export default Dropdown;
