import {
  StyledMobileButton,
  StyledMobileSearchWrapper,
} from "../Header-styled";
import React, { useCallback, useContext, useEffect, useRef } from "react";
import { MOBILE_SCREENS } from "../../App/App";
import SearchWidget from "../../SearchWidget/SearchWidget";
import { view } from "../../../utils/API";
import useClickOutside from "../../../hooks/useClickOutside";
import { SearchIcon } from "../../Icons";
import { ConfigContext } from "../../../utils/ConfigContext";
import { useSelector } from "react-redux";
import { isWidgetDisplayed } from "../../../esri/widgets/expandUtils";
import SearchBox from "../../SearchWidget/SearchBox";
import { focusSearchInput } from "../../SearchWidget/helpers";

const MobileSearch = ({ t, mobileScreen, expandSearch, setExpandSearch }) => {
  const { config, searchListText, setSearchListText } =
    useContext(ConfigContext);
  const { isMobileApp, activeList } = useSelector(
    (state) => state.mobileReducer
  );

  const ref = useRef();

  useEffect(() => {
    setSearchListText("");
  }, [activeList]);

  useClickOutside(ref, () => {
    // setExpandSearch(false);
  });

  const handleSearchBack = useCallback((e) => {
    if (e) {
      e.stopPropagation();
    }

    setExpandSearch(false);
    setSearchListText("");
  }, []);

  const handleSearch = useCallback((e) => {
    const { value } = e.target;
    setSearchListText(value);
  }, []);

  const renderSearch = () => {
    switch (mobileScreen) {
      case MOBILE_SCREENS.MAP: {
        return (
          <SearchWidget
            t={t}
            config={config}
            view={view}
            onBackClick={handleSearchBack}
            isMobileApp={isMobileApp}
            isExpanded={expandSearch}
          />
        );
      }
      case MOBILE_SCREENS.TABLE: {
        return (
          <SearchBox
            placeholder={t(
              "screen.widget.Search.placeholder",
              "Search on LogIE"
            )}
            onBackClick={handleSearchBack}
            value={searchListText}
            onChange={handleSearch}
            config={config}
            isMobileApp={isMobileApp}
          />
        );
      }
      default: {
        return null;
      }
    }
  };

  return (
    <StyledMobileButton
      ref={ref}
      style={{
        width: expandSearch ? "100%" : "40px",
        padding: expandSearch ? 0 : undefined,
        background: expandSearch ? "none" : undefined,
        position: "absolute",
        right: expandSearch ? 0 : 8,
        top: "50%",
        transform: "translateY(-50%)",
      }}
      className="onboarding-search"
      onClick={() => {
        if (expandSearch) return;
        setExpandSearch(true);
        focusSearchInput();
      }}
    >
      <SearchIcon
        style={{
          display: expandSearch ? "none" : "block",
        }}
      />
      {view && (
        <StyledMobileSearchWrapper
          style={{
            display: expandSearch ? "block" : "none",
          }}
          mobileScreen={mobileScreen}
        >
          {renderSearch()}
        </StyledMobileSearchWrapper>
      )}
    </StyledMobileButton>
  );
};

export default MobileSearch;
