import { CustomLoader } from "../App/App-styled";
import { ShowMore } from "../LatestUpdates/LatestUpdates-styled";
import { LatestUpdatesWrapper } from "../LatestUpdates/new/LatestUpdates-styled";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import usePullToRefresh from "../../hooks/usePoolToRefresh";
import { view } from "../../utils/API";
import {
  ROLE_EDITOR,
  clickEventOnFeature,
  getOpsColor,
  toggleEditorWidget,
} from "../../utils/helper";
import useInView from "../../hooks/useInView";
import { ConfigContext } from "../../utils/ConfigContext";
import { useTranslation } from "react-i18next";
import { loadModules } from "esri-loader";
import { useSelector } from "react-redux";
import {
  getFeatureTitle,
  getLayerTitle,
} from "../../esri/custom-popup-content";
import { ArrowLeftIcon } from "../Icons";
import { StyledFiltersBackButton } from "../MobileApp/MobileFilter/MobileFilter-styled";
import ReportCard from "./ReportCard";
import SharedInformationHeader from "./SharedInformationHeader";
import ProgressBar from "../Report/new/ProgressBar/ProgressBar";
import { StyledEditorBreadcrumbs } from "../Dashboard/Editor/Editor-styled";

const getFeatureEditDate = (feature) => {
  const editDateFieldName = feature.reportLayer
    ? feature.reportLayer.editFieldsInfo?.editDateField
    : feature.layer.editFieldsInfo?.editDateField;
  if (!editDateFieldName) return;

  return feature.reportLayer
    ? feature.attributes[`report-${editDateFieldName}`]
    : feature.attributes[editDateFieldName];
};

const compareFeatures = (a, b, sort = "descending") => {
  const editDateA = getFeatureEditDate(a);
  const editDateB = getFeatureEditDate(b);

  if (!editDateA) return 1;

  if (!editDateB) return 1;

  if (sort === "descending") {
    return editDateA > editDateB ? -1 : 1;
  } else {
    return editDateA < editDateB ? -1 : 1;
  }
};

const SharedInformation = ({ expand, handleGoBack, selectedTab }) => {
  const [features, setFeatures] = useState([]);
  const [lastDate, setLastDate] = useState(new Date());
  const [layer, setLayer] = useState(null);
  const [queryLoading, setQueryLoading] = useState(false);
  const [showMoreActive, setShowMoreActive] = useState(true);
  const [showMoreLoading, setShowMoreLoading] = useState(true);
  const [showMoreOffset, setShowMoreOffset] = useState(20);
  const [sortType, setSortType] = useState("descending");
  const [extent, setExtent] = useState();
  const [count, setCount] = useState(0);

  const { config } = useContext(ConfigContext);
  const { t } = useTranslation("common");

  const abortController = useRef({});
  const layerDefinitionWatch = useRef({});
  const watchHandlers = useRef([]);
  const refreshRef = useRef();
  const loaderRef = useRef();

  const { activeModule } = useSelector((state) => state);
  const [reportedFeature, setReportedFeature] = useState(null);

  usePullToRefresh(
    refreshRef,
    () => {
      view.map.layers.forEach((layer) => {
        if (!layer.layerConfig?.isShownInReportManager && !layer.visible) {
          return;
        }
        setCount((count) => count + 1);
      });
    },
    loaderRef
  );

  const visibleLayers = useRef({});

  useEffect(() => {
    if (watchHandlers.current.length > 0) {
      watchHandlers.current.forEach((h) => h.remove());
    }

    if (listeners.current.length > 0) {
      listeners.current.forEach((handler) => handler.remove());
    }

    const layersChangeHandler = view.map.layers.on("change", (event) => {
      if (event.added) {
        event.added.forEach((layer) => {
          if (!layer.layerConfig?.isShownInReportManager) {
            return;
          }
          visibleLayers.current[layer.id] = layer.visible;
          const arrayVisibleLayers = Object.values(
            visibleLayers.current
          ).filter((visible) => visible);
          // setShow(arrayVisibleLayers.length > 0)

          setCount((count) => count + 1);
          const editHandler = layer.on("edits", () => {
            setCount((count) => count + 1);
          });
          const refreshHandler = layer.on("refresh", () => {
            setCount((count) => count + 1);
          });
          const visibleHandler = layer.watch("visible", (visible) => {
            visibleLayers.current[layer.id] = visible;
            const arrayVisibleLayers = Object.values(
              visibleLayers.current
            ).filter((visible) => visible);
            // setShow(arrayVisibleLayers.length > 0)

            if (visible) {
              setCount((count) => count + 1);
            } else {
              if (Array.isArray(abortController.current[layer.id])) {
                abortController.current[layer.id].forEach((controller) =>
                  controller.abort()
                );
              }
              layerDefinitionWatch.current[layer.id]?.remove();
            }
          });
          watchHandlers.current.push(
            editHandler,
            refreshHandler,
            visibleHandler
          );
        });
      }
    });
    watchHandlers.current.push(layersChangeHandler);

    view.map.layers.forEach((layer) => {
      if (!layer.layerConfig?.isShownInReportManager) {
        return;
      }
      visibleLayers.current[layer.id] = layer.visible;
      const arrayVisibleLayers = Object.values(visibleLayers.current).filter(
        (visible) => visible
      );
      // setShow(arrayVisibleLayers.length > 0)

      setCount((count) => count + 1);

      //Update page when edit or refresh events occur:
      const editHandler = layer.on("edits", () => {
        setCount((count) => count + 1);
      });
      const refreshHandler = layer.on("refresh", () => {
        setCount((count) => count + 1);
      });
      const visibleHandler = layer.watch("visible", (visible) => {
        visibleLayers.current[layer.id] = visible;
        const arrayVisibleLayers = Object.values(visibleLayers.current).filter(
          (visible) => visible
        );

        // setShow(arrayVisibleLayers.length > 0)

        if (visible) {
          setCount((count) => count + 1);
        } else {
          abortController.current[layer.id].forEach((controller) =>
            controller.abort()
          );
          layerDefinitionWatch.current[layer.id]?.remove();
        }
      });
      watchHandlers.current.push(editHandler, refreshHandler, visibleHandler);
    });

    return () => {
      watchHandlers.current.forEach((h) => h.remove());
    };
  }, [selectedTab]);

  const listeners = useRef([]);
  const timers = useRef();
  useEffect(() => {
    const layers = [];
    clearTimeout(timers.current);
    loadModules(["esri/core/reactiveUtils"]).then(([reactiveUtils]) => {
      timers.current = setTimeout(() => {
        view.map.layers.forEach((layer) => {
          if (visibleLayers.current[layer.id]) {
            processLayer(layer);
            layers.push(layer);
          }
        });

        if (extent) {
          const stationary = reactiveUtils.when(
            () => !!view?.stationary,
            () => {
              if (!extent) return;
              layers.forEach((layer) => {
                processLayer(layer);
              });
            },
            { initial: false }
          );
          listeners.current.push(stationary);
        } else {
          listeners.current?.forEach((handler) => handler.remove());
        }
      }, 250);
    });
  }, [count, extent]);

  const reportedFeatureRef = useRef();
  useEffect(() => {
    reportedFeatureRef.current = reportedFeature;
  }, [reportedFeature]);

  useEffect(() => {
    const checkFeature = (feature) => {
      let id;
      if (feature) {
        if (feature.attributes.reportedfeatureid) {
          id = feature.attributes.reportedfeatureid;
        } else {
          const layer = feature?.sourceLayer || feature?.layer;
          if (layer) {
            id = feature.attributes[layer.objectIdField];
          }

          const reportedFeature = features.find(
            (feat) => feat.getObjectId() === id && feat.reports
          );
          if (reportedFeature) {
            setReportedFeature(reportedFeature);
          }
        }
      }
    };

    let handler, selectedFeatureHandler;
    loadModules(["esri/core/reactiveUtils"]).then(([reactiveUtils]) => {
      handler = reactiveUtils.watch(
        () => view.popup.visible,
        (visible) => {
          if (visible) {
            checkFeature(view.popup.selectedFeature);
            selectedFeatureHandler?.remove();
            selectedFeatureHandler = reactiveUtils.watch(
              () => view.popup.selectedFeature,
              (feature) => {
                checkFeature(feature);
              }
            );
          } else if (!view.popup.selectedFeature) {
            const editorWidget = view.ui.find("Editor");
            if (editorWidget?.expanded) {
              return;
            }

            selectedFeatureHandler?.remove();
            setReportedFeature(null);
          }
        },
        { initial: true }
      );
    });

    return () => {
      handler?.remove();
      selectedFeatureHandler?.remove();
    };
  }, [selectedTab, features]);

  const watchTimer = useRef({});
  const processLayer = (layer) => {
    if (!layer.visible) return;
    if (layerDefinitionWatch.current[layer.id]) {
      layerDefinitionWatch.current[layer.id].remove();
      layerDefinitionWatch.current[layer.id] = undefined;
    }

    setLayer(layer);

    setQueryLoading(true);
    layer.load().then((l) => {
      queryLayer(layer);
      layerDefinitionWatch.current[layer.id] = layer.watch(
        "definitionExpression",
        () => {
          if (layer.visible) {
            clearTimeout(watchTimer.current[layer.id]);
            watchTimer.current[layer.id] = setTimeout(() => {
              queryLayer(layer);
            }, 500);
          }
        }
      );
    });
  };

  const queryLayer = async (layer) => {
    setQueryLoading(true);
    try {
      if (abortController.current[layer.id]?.length > 0) {
        abortController.current[layer.id].forEach((controller) => {
          controller.abort();
        });
        abortController.current[layer.id] = [];
      }

      setLastDate(new Date());
      const query = layer.createQuery();

      if (extent) {
        query.geometry = view.extent;
      }

      abortController.current[layer.id] = [];
      abortController.current[layer.id].push(new AbortController());

      const res = await layer.queryFeatures(query, {
        signal: abortController.current[layer.id][0].signal,
      });
      const oidField = layer.objectIdField;
      const objectIds = res.features.map(
        (feature) => feature.attributes[oidField]
      );

      const reportedLayersMap = {};
      const reportedFeaturesMap = {};
      let newReports = 0;

      const filteredFeatures = res.features.filter((feat) => {
        const { reportedlayerid, reportedfeatureid } = feat.attributes;
        if (feat.attributes.currvalidationfield === 1) {
          newReports++;
        }

        if (!!reportedfeatureid && !!reportedlayerid) {
          if (reportedLayersMap[reportedlayerid]) {
            reportedLayersMap[reportedlayerid].push(reportedfeatureid);
          } else {
            reportedLayersMap[reportedlayerid] = [reportedfeatureid];
          }

          if (reportedFeaturesMap[`${reportedlayerid}-${reportedfeatureid}`]) {
            reportedFeaturesMap[`${reportedlayerid}-${reportedfeatureid}`].push(
              feat
            );
          } else {
            reportedFeaturesMap[`${reportedlayerid}-${reportedfeatureid}`] = [
              feat,
            ];
          }
        }

        return !(!!reportedfeatureid && !!reportedlayerid);
      });

      const reportedLayersIds = Object.keys(reportedLayersMap);
      const promises = [];

      view.map.layers.forEach((layer) => {
        if (
          reportedLayersIds.includes(layer.layerConfig?.viewer) ||
          reportedLayersIds.includes(layer.layerConfig?.id)
        ) {
          const viwerIndex = reportedLayersIds.findIndex(
            (id) => id === layer.layerConfig.viewer
          );
          const idIndex = reportedLayersIds.findIndex(
            (id) => id === layer.layerConfig.id
          );
          const layerIds = [];
          if (viwerIndex > -1) {
            layerIds.push(reportedLayersIds[viwerIndex]);
          }

          if (idIndex > -1) {
            layerIds.push(reportedLayersIds[idIndex]);
          }

          const query = layer.createQuery();
          const featureIds = [];
          layerIds.forEach((id) => {
            featureIds.push(...reportedLayersMap[id]);
          });

          query[layer.objectIdField] = featureIds;

          query.where = `${layer.objectIdField} IN (${featureIds.join(", ")})`;
          promises.push(layer.queryFeatures(query));
        }
      });

      const reportedFeatures = [];

      const results = await Promise.allSettled(promises);
      const reportedFeaturesResult = results
        .filter((res) => res.status === "fulfilled")
        .map((res) => res.value);
      reportedFeaturesResult.forEach((res) => {
        if (res?.features.length > 0) {
          res.features.map((feat) => {
            const newFeature = feat.clone();
            let featureArray =
              reportedFeaturesMap[
                `${feat.layer.layerConfig.viewer}-${feat.getObjectId()}`
              ];

            if (!featureArray) {
              featureArray =
                reportedFeaturesMap[
                  `${feat.layer.layerConfig.id}-${feat.getObjectId()}`
                ];
            }

            newFeature.reports = featureArray;

            if (newFeature.reports.length > 0) {
              const latestFeature = newFeature.reports.sort((a, b) =>
                compareFeatures(a, b)
              )[0];
              newFeature.reportLayer = latestFeature.layer;

              const editDateFieldName =
                latestFeature.layer.editFieldsInfo?.editDateField;
              const editField = editDateFieldName; //latestFeature.attributes[currAsOfDateFieldName] ? currAsOfDateFieldName : editDateFieldName

              if (editField) {
                newFeature.attributes[`report-${editField}`] =
                  latestFeature.attributes[editField];
              }
            }

            reportedFeatures.push(newFeature);
            return newFeature;
          });
        }
      });

      if (objectIds.length > 0) {
        abortController.current[layer.id].push(new AbortController());
        const response = await layer.queryAttachments(
          { objectIds: objectIds },
          { signal: abortController.current[layer.id][1].signal }
        );

        response &&
          Object.keys(response).forEach((oid) => {
            // Each object might have different attachments
            const objectAtts = response[oid];
            filteredFeatures.forEach((feature) => {
              if (feature.attributes[oidField].toString() === oid) {
                feature.attachments = objectAtts;
              }
            });
          });
      }

      if (reportedFeature) {
        const reportedFeat = reportedFeatures.find(
          (feat) => feat.getObjectId() === reportedFeature.getObjectId()
        );
        setReportedFeature(reportedFeat);
      }

      setFeatures([...reportedFeatures, ...filteredFeatures]);

      setQueryLoading(false);
      setShowMoreActive(true);
      setShowMoreLoading(false);
    } catch (err) {
      if (err.name === "AbortError") return;
      console.log(err);
      setQueryLoading(false);
    }
  };

  const timeout = useRef(0);

  const onShowMoreClicked = () => {
    if (!showMoreActive || timeout) return;
    if (features.length <= showMoreOffset) {
      setShowMoreActive(false);
      return;
    }

    setShowMoreLoading(true);
    timeout.current = setTimeout(() => {
      setShowMoreOffset((prev) => prev + 20);
      setShowMoreLoading(false);
    }, 350);
  };

  const featuresToShow = useMemo(() => {
    let newFeatures = features; //.filter(feat=>selectedOptions.length > 0 ? selectedOptions.includes(feat.attributes['currvalidationfield']): true);
    // if (popupReportedFeatureId){
    //   const filteredFeatures = newFeatures.filter(item => item.attributes.reportedfeatureid === popupReportedFeatureId)
    //   if (filteredFeatures.length !== 0) {
    //     newFeatures = filteredFeatures;
    //   }
    // }

    if (newFeatures.length > showMoreOffset) {
      return newFeatures.sort((a, b) => compareFeatures(a, b, sortType));
      // .slice(0, showMoreOffset)
    }
    return newFeatures.sort((a, b) => compareFeatures(a, b, sortType));
  }, [features, showMoreOffset, sortType]);

  const observerTarget = useRef(null);
  useInView(observerTarget, onShowMoreClicked, [
    showMoreActive,
    onShowMoreClicked,
  ]);

  const opsColor = useMemo(
    () => getOpsColor(config, activeModule),
    [config, activeModule]
  );
  const handleFeatureClick = (feature) => {
    toggleEditorWidget(false);
    clickEventOnFeature(feature);
  };

  const renderTitle = (feature, t) => {
    if (!feature) return "";
    const title =
      getFeatureTitle(feature, t) ||
      getLayerTitle(feature.sourceLayer || feature.layer, t);
    return title;
  };

  return (
    <LatestUpdatesWrapper
      expand={expand}
      style={{
        background: "#eee",
      }}
    >
      {/*for editor*/}
      {config.role === ROLE_EDITOR && config.newEditorEnabled && (
        <StyledEditorBreadcrumbs hasProgress>
          <ProgressBar color={config.opsColor} currentPosition={1} total={1} />
          <p>{t("screen.widget.ReportManager.title")}</p>
          <button onClick={handleGoBack}>
            <svg
              fillRule="evenodd"
              height="10"
              role="img"
              viewBox="0 0 10 10"
              width="10"
              aria-label="description"
            >
              <path d="M6.32 5L10 8.68 8.68 10 5 6.32 1.32 10 0 8.68 3.68 5 0 1.32 1.32 0 5 3.68 8.68 0 10 1.32 6.32 5z"></path>
            </svg>
          </button>
        </StyledEditorBreadcrumbs>
      )}
      <div
        style={{
          background: "#eee",
        }}
      >
        {!reportedFeature && (
          <SharedInformationHeader
            config={config}
            loading={queryLoading}
            layer={layer}
            lastDate={lastDate}
          />
        )}
      </div>

      {reportedFeature ? (
        <div
          style={{
            overflow: "auto",
            display: "flex",
            flexDirection: "column",
            flex: 1,
            padding: "0px 0px 10px",
            background: "#eee",
            gap: 6,
            boxSizing: "border-box",
          }}
        >
          <div
            style={{
              display: "flex",
              padding: "4px 6px",
              margin: "6px 4px 0px",
              alignItems: "center",
              gap: 8,
              background: "#fff",
              borderRadius: 100,
            }}
          >
            <StyledFiltersBackButton
              style={{
                minWidth: "24px",
                height: "24px",
              }}
              className="filter__arrow-left"
              onClick={() => {
                setReportedFeature(null);
                view.closePopup();
                toggleEditorWidget(false);
              }}
            >
              <ArrowLeftIcon width="14" height="14" />
            </StyledFiltersBackButton>
            <div style={{ display: "flex", gap: 4 }}>
              <span style={{ fontSize: 18 }}>
                {renderTitle(reportedFeature, t)}
              </span>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 6,
              overflow: "auto",
            }}
          >
            {reportedFeature.reports.map((report) => (
              <ReportCard
                key={`rf-${report.getObjectId()}`}
                selectedFeature={report}
              />
            ))}
          </div>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            position: "relative",
            background: "inherit",
            flex: 1,
            overflow: "hidden",
          }}
        >
          <div
            ref={loaderRef}
            id="refresh"
            style={{
              opacity: 0,
              position: "absolute",
              top: 0,
              left: "50%",
              transform: "translateX(-50%)",
            }}
          >
            <CustomLoader opsColor={opsColor} scale="s" />
          </div>
          <div
            ref={refreshRef}
            style={{
              overflow: "auto",
              display: "flex",
              flexDirection: "column",
              flex: 1,
              padding: "0px 0px 10px",
              marginTop: "10px",
              gap: 8,
              boxSizing: "border-box",
            }}
          >
            {featuresToShow.map((feature) => {
              return (
                <ReportCard
                  key={`rc-${feature.getObjectId()}`}
                  selectedFeature={feature}
                  onClick={() => handleFeatureClick(feature)}
                />
              );
            })}
            <ShowMore
              ref={observerTarget}
              onClick={() => {
                if (showMoreActive) {
                  onShowMoreClicked();
                }
              }}
            >
              {showMoreLoading && (
                <CustomLoader opsColor={opsColor} scale="s" />
              )}
              {!queryLoading &&
                !showMoreLoading &&
                !showMoreActive &&
                t("screen.message.noMoreInformation")}
            </ShowMore>
          </div>
        </div>
      )}
    </LatestUpdatesWrapper>
  );
};

export default SharedInformation;
