import { useState, useCallback, useContext, useRef } from "react";
import { ConfigContext } from "../../utils/ConfigContext";
import { getConfigISO } from "../../utils/helper";

const buildQueryString = (params) => {
  const searchParams = new URLSearchParams();
  Object.keys(params).forEach((key) => {
    searchParams.append(key, params[key]);
  });
  return searchParams.toString();
};

const useORSSearch = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { config } = useContext(ConfigContext);
  const searchAbortControllerRef = useRef(null);
  const autocompleteAbortControllerRef = useRef(null);

  const cancelPreviousSearchRequest = () => {
    if (searchAbortControllerRef.current) {
      searchAbortControllerRef.current.abort();
    }
    searchAbortControllerRef.current = new AbortController();
  };

  const cancelPreviousAutocompleteRequest = () => {
    if (autocompleteAbortControllerRef.current) {
      autocompleteAbortControllerRef.current.abort();
    }
    autocompleteAbortControllerRef.current = new AbortController();
  };

  const fetchGeocodeSearch = useCallback(
    async (query) => {
      try {
        cancelPreviousSearchRequest();
        setLoading(true);
        setError(null);

        const iso3 = getConfigISO(config);

        const params = {
          api_key: process.env.REACT_APP_ORS_API_KEY,
          text: query,
          "boundary.country": iso3.join(","),
        };

        const url = `https://api.openrouteservice.org/geocode/search?${buildQueryString(
          params
        )}`;

        const response = await fetch(url, {
          signal: searchAbortControllerRef.current.signal,
        });
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setData(data);
        return data;
      } catch (err) {
        if (err.name !== "AbortError") {
          console.warn(err);
          setError(err);
        }
      } finally {
        setLoading(false);
      }
    },
    [config]
  );

  const fetchGeocodeAutocomplete = useCallback(
    async (query) => {
      try {
        cancelPreviousAutocompleteRequest();
        setLoading(true);
        setError(null);
        const iso3 = getConfigISO(config);

        const params = {
          api_key: process.env.REACT_APP_ORS_API_KEY,
          text: query,
          "boundary.country": iso3.join(","),
        };

        const url = `https://api.openrouteservice.org/geocode/autocomplete?${buildQueryString(
          params
        )}`;
        const response = await fetch(url, {
          signal: autocompleteAbortControllerRef.current.signal,
        });
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();

        setData(data);
        return data;
      } catch (err) {
        if (err.name !== "AbortError") {
          console.warn(err);
          setError(err);
        }
      } finally {
        setLoading(false);
      }
    },
    [config]
  );

  return {
    data,
    loading,
    error,
    fetchGeocodeSearch,
    fetchGeocodeAutocomplete,
  };
};

export default useORSSearch;
