import styled from "styled-components";

export const FeatureImageSwiperWrapper = styled.div`
  width: 100%;
  height: ${(props) => props.height || "300px"};
  overflow: hidden;
  box-sizing: border-box;

  padding: 8px 4px;
  border-radius: 8px;
  backdrop-filter: blur(10px);

  .swiper {
    width: 100%;
    height: 100%;
  }

  .swiper-slide {
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 100%;
  }

  .swiper-slide-shadow {
    background: none;
  }

  img {
    width: 100%;
    height: 100% !important;
    object-fit: contain;
    border-radius: 8px;
    overflow: hidden;
  }
`;

export const NavigationButtonsWrapper = styled.div`
  width: calc(100% - 24px);
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: 0px;
  /* transform: translateY(-50%); */
  left: 12px;
  z-index: 1;
  pointer-events: none;

  button:first-child {
    transform: rotate(180deg);
  }

  button {
    pointer-events: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px;
    background-color: rgb(242 241 241 / 70%);
    backdrop-filter: blur(10px);
    border: none;
    cursor: pointer;
    border-radius: 50%;
    width: 22px;
    height: 22px;
    transition: 0.3s ease-in-out;

    svg {
      fill: #595959;
      width: 14px;
      height: 14px;
    }
  }
`;

export const NavigationCount = styled.div`
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translate(-50%, -90%);
  background-color: rgb(0 0 0 / 30%);
  backdrop-filter: blur(50px);
  border-radius: 10px;
  display: flex;
  padding: 4px 8px;

  span {
    font-size: 12px;
    line-height: normal;
    color: #fff;
  }
`;
