import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { loadModules } from "esri-loader";
import { composeLayerQuery, isCoordSearch } from "../../esri/widgets/search";
import {
  getFeatureTitle,
  getLayerTitle,
} from "../../esri/custom-popup-content";
import { countryCodes } from "../../data/countryCodes";
import {
  StyledLocateButton,
  StyledSuggestGroup,
  StyledSuggestions,
  StyledSuggestItem,
} from "./SearchWidget-styled";
import { StyledSearchBox } from "../Dashboard/Editor/Editor-styled";
import Loader from "../Dashboard/Editor/Loader/Loader";
import CoordinatesInputs from "../Dashboard/Editor/CoordinatesSelection/CoordinatesInputs";
import useLayersChange from "../../hooks/useLayersChange";
import useClickOutside from "../../hooks/useClickOutside";
import { clickEventOnFeature } from "../../utils/helper";
import { FaSearchLocation } from "../Icons";
import SearchBox from "./SearchBox";
import useORSSearch from "../../hooks/Search/useORSSearch";
import LayerIcon from "../Icons/LayerIcon";

const patterns = {
  dd: /^(\s*[NSns+-]?\s*)(\d+([.,]\d+)?)(\s*[NSns+-]?\s*)(\s*[-,\s;\/]\s*)(\s*[EWOewo+-]?\s*)(\d+([.,]\d+)?)(\s*[EWOewo+-]?\s*)\s*$/,
  ddm: /^(\s*[NSns+-]?\s*)(\d+)[°\s]+(\d+(\.\d+)?)[`'′]\s*(\s*[NSns+-]?\s*)([,-\s;/]\s*)([EWOewo+-]?\s*)(\d+)[°\s]+(\d+(\.\d+)?)[`'′]\s*([EWOewo+-]?\s*)$/,
  dms: /^(\s*[NSns+-]?\s*)(\d+)[°\s]+(\d+(\.\d+)?)[`'′]\s*(\d+(\.\d+)?)[`"′]\s*(\s*[NSns+-]?\s*)([,-\s;/]\s*)([EWOewo+-]?\s*)(\d+)[°\s]+(\d+(\.\d+)?)[`'′]\s*(\d+(\.\d+)?)[`"′]\s*([EWOewo+-]?\s*)$/,
};

const martinPatterns = {
  dd: /^(\s*[NSns+-]?\s*)(\d+(\.\d+)?)(\s*[,\s;/]\s*)([EWOewo+-]?\s*)(\d+(\.\d+)?)\s*$/,
  ddm: /^(\s*[NSns+-]?\s*)(\d+)[°\s]+(\d+(\.\d+)?)[`'′]\s*([,\s;/]\s*)([EWOewo+-]?\s*)(\d+)[°\s]+(\d+(\.\d+)?)[`'′]\s*$/,
  dms: /^(\s*[NSns+-]?\s*)(\d+)[°\s]+(\d+)[`'′]\s+(\d+(\.\d+)?)[\"”″]\s*([,\s;/]\s*)([EWOewo+-]?\s*)(\d+)[°\s]+(\d+)[`'′]\s+(\d+(\.\d+)?)[\"”″]\s*$/,
};

const checkForCoordinates = (text) => {
  console.log(text.match(martinPatterns.dd));
  console.log(text.match(martinPatterns.ddm));
  console.log(text.match(martinPatterns.dms));
};

const SearchWidget = ({
  config,
  t,
  view,
  onBackClick,
  isMobileApp,
  rotate,
  isExpanded,
}) => {
  const [search, setSearch] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showNoResults, setShowNoResults] = useState(false);
  const [showCoordinates, setShowCoordinates] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [showDropdown, setShowDropdown] = useState(!isMobileApp);
  const [value, setValue] = useState("");
  const { layerCount, visibleLayersIds } = useLayersChange(config);
  const [activeCoordinates, setActiveCoordinates] = useState(null);
  const [selectedResultKey, setSelectedResultKey] = useState(null);
  const { fetchGeocodeSearch } = useORSSearch();

  const activeModule = useSelector((state) => state.activeModule);

  const ref = useRef();
  const expandedRef = useRef();
  const searchRef = useRef();

  const removeSearchGraphic = () => {
    if (!view) return;
    const searchGraphic = view.graphics.find(
      (g) => g?.symbol?.url && g?.symbol?.url.includes("search")
    );

    if (searchGraphic) {
      view.graphics.remove(searchGraphic);
    }
  };

  const resetSearch = useCallback(() => {
    setValue("");
    setShowCoordinates(false);
    setShowNoResults(false);
    setLoading(false);
    setSuggestions([]);
    setActiveCoordinates(null);
    setSelectedResultKey(null);
    setShowDropdown(false);

    if (search) {
      removeSearchGraphic();
      search.clear();
    }
    clearTimeout(timer.current);
  }, [search]);

  useClickOutside(ref, () => {
    if (!isMobileApp) {
      setShowCoordinates(false);
      setShowNoResults(false);
    }
  });

  useEffect(() => {
    if (!isExpanded) {
      resetSearch();
    }
    expandedRef.current = isExpanded;
  }, [isExpanded]);

  const initialExtent = useMemo(() => {
    return view.extent;
  }, []);

  const addLayerToSearch = async (layer, sw) => {
    const [LayerSearchSource, Query, Graphic, PictureMarkerSymbol] =
      await loadModules([
        "esri/widgets/Search/LayerSearchSource",
        "esri/rest/support/Query",
        "esri/Graphic",
        "esri/symbols/PictureMarkerSymbol",
      ]);

    if (layer.type !== "feature") return null;
    const searchConfig = layer.layerConfig?.search;
    if (searchConfig && searchConfig.enabled === false) return;

    //exclude not feature layers and the global country layer
    if (!layer.fields || layer.title === "World Countries (Generalized)")
      return;

    let searchFields = layer.fields
      .filter((field) => field.type === "string")
      .map((field) => field.name);
    if (searchFields.length === 0) return;

    //Filter fields
    if (searchConfig && searchConfig.searchFields) {
      const fields = searchConfig.searchFields;
      if (!Array.isArray(fields) || fields.length === 0) {
        console.warn(
          "Layer configuration error found. Layer: " +
            layer.title +
            " Error: searchField attribute must be an array of strings with at least one value"
        );
      } else {
        searchFields = searchFields.filter((layerField) =>
          fields.includes(layerField)
        );
      }
    }

    //Is layer already loaded?
    if (
      sw.sources.filter((locator) => locator.layer && locator.layer === layer)
        .length > 0
    )
      return;

    const symbol = new PictureMarkerSymbol({
      url: "/assets/symbols/pin_symbol.svg", // Path to your SVG file in the public/assets folder
      width: "34px",
      height: "34px",
    });

    let layerSearchSource = new LayerSearchSource({
      layer: layer,
      name: getLayerTitle(layer, t),
      popupEnabled: false,
      enableSuggestions: true,
      getSuggestions: (params) => {
        if (!layer.visible) return;

        const query = composeLayerQuery(
          params.suggestTerm,
          Query,
          config,
          layer,
          searchFields
        );
        return layer.queryFeatures(query).then((results) => {
          return results.features.map((feature) => {
            let suggestion = getFeatureTitle(feature, t);
            if (!suggestion)
              suggestion = t("screen.widget.Search.noTitle", "No title");

            return {
              key: feature.attributes[layer.objectIdField]
                ? feature.attributes[layer.objectIdField]
                : "key",
              text: suggestion,
              sourceIndex: params.sourceIndex,
              feature: feature,
            };
          });
        });
      },
      getResults: (params) => {
        if (!params.suggestResult || !params.suggestResult.feature) return null;

        //Mark the result, that is coming from the layer search source
        return new Promise((resolve) => {
          const feature = params.suggestResult.feature;
          const graphic = new Graphic({
            geometry: feature.geometry,
            attributes: feature.attributes,
          });

          //Mark the result, that is coming from the layer search source
          return resolve([
            {
              feature: feature,
              target: graphic,
              isLayerSearchSourceResult: true,
            },
          ]);
        });
      },
      filter: layer.definitionExpression
        ? { where: layer.definitionExpression }
        : {},
      exactMatch: false,
      outFields: ["*"],
      resultSymbol: symbol,
    });

    if (initialExtent) layerSearchSource.zoomScale = initialExtent.width / 3;

    // sw.sources = sw.sources.filter(
    //   (source) =>
    //     layerSearchSource.layer?.layerConfig?.id !==
    //     source.layer?.layerConfig?.id
    // );

    sw.sources.push(layerSearchSource);
    sw.sources.sort((a, b) => {
      if (a.layer && !b.layer) return 1;
      else if (!a.layer && b.layer) return -1;

      return a.name.localeCompare(b.name);
    });
  };

  useEffect(() => {
    const createWidget = async () => {
      const [Search, PictureMarkerSymbol] = await loadModules([
        "esri/widgets/Search",
        "esri/symbols/PictureMarkerSymbol",
      ]);

      const symbol = new PictureMarkerSymbol({
        url: "/assets/symbols/pin_symbol.svg", // Path to your SVG file in the public/assets folder
        width: "34px",
        height: "34px",
      });

      const sw = new Search({
        view: view,
        locationEnabled: false,
        includeDefaultSources: false,
        minSuggestCharacters: 2,
        sources: [],
        popupEnabled: false,
        resultGraphicEnabled: true,
        resultSymbol: symbol,
      });

      const arcgisSource = {
        name: t(
          "screen.widget.Search.geocoding",
          "ArcGIS World Geocoding Service"
        ),
        url: "https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer",
        singleLineFieldName: "SingleLine", //to search by coordinates on copy paste
        zoomScale: initialExtent?.extent?.width / 3,
        type: "arcgis-geocoder",
        resultSymbol: symbol,
      };

      const country = countryCodes.find((country) =>
        Array.isArray(config.iso3)
          ? config.iso3.includes(country.ISO3)
          : country.ISO3 === config.iso3
      );

      if (country) {
        arcgisSource.countryCode = country.ISO2;
      }

      sw.sources.push(arcgisSource);
      sw.when(() => {
        setSearch(sw);
        searchRef.current = sw;
        sw.goToOverride = (view, params) => {
          // if (!isCoordSearch(sw.searchTerm) || window.isSmall){
          //   // setShowCoordinates(false);
          // }

          return view.goTo({
            target: params.target.target,
            scale: initialExtent.width,
            duration: 1000, // duration for the animation
          });
        };
      });
    };

    createWidget();

    return () => {
      removeSearchGraphic();
    };
  }, []);

  useEffect(() => {
    if (!search) return;
    resetSearch();
    onBackClick();
  }, [activeModule]);

  useEffect(() => {
    if (!search || !view) return;
    const visibleLayers = view.map.layers.filter((l) => visibleLayersIds[l.id]);

    visibleLayers.forEach((l) => {
      addLayerToSearch(l, search);
    });
  }, [layerCount, search]);

  const timer = useRef(0);
  const coordinatesFlag = useRef(false);

  const hideDropdown = useCallback(() => {
    //hiding dropdown for mobile app
    if (!isMobileApp) return;
    setShowDropdown(false);
  }, []);

  const handleInputChange = useCallback(
    async (e) => {
      const inputText = e.target.value;

      setShowNoResults(false);
      setValue(inputText);
      setSelectedResultKey(null);
      if (search) {
        removeSearchGraphic();
        search.clear();
      }

      clearTimeout(timer.current);
      timer.current = setTimeout(async () => {
        if (!inputText || inputText.length < 2) {
          setShowNoResults(false);
          setSuggestions([]);
          setActiveCoordinates(null);
          setShowCoordinates(true);
          coordinatesFlag.current = true;
          return;
        }

        if (isCoordSearch(inputText)) {
          const [latitude, longitude] = inputText.split(",");
          coordinatesFlag.current = true;
          const point = {
            latitude: latitude?.trim(),
            longitude: longitude?.trim(),
          };

          handleCoordinates(point);
          setSuggestions([]);
          setShowCoordinates(true);
        } else {
          setLoading(true);
          if (activeCoordinates) {
            setActiveCoordinates(null);
          }
          try {
            coordinatesFlag.current = false;
            const suggestResult = await search.suggest(inputText);
            const geoSearch = await fetchGeocodeSearch(inputText);

            const hasGeoSearch =
              Array.isArray(geoSearch?.features) &&
              geoSearch.features.length > 0;
            if (!expandedRef.current || coordinatesFlag.current) {
              setLoading(false);
              return;
            }

            const { results = [] } = suggestResult || {};
            const availableResults = [];
            if (hasGeoSearch) {
              availableResults.push({
                label: "GeoSearch",
                results: geoSearch.features.map((feature) => {
                  return {
                    key: feature.properties.id,
                    text: feature.properties.label,
                    feature: feature,
                  };
                }),
                key: `${Math.random()}-GeoSearch`,
              });
            }

            results.forEach((result) => {
              if (
                result.results.length === 0 ||
                (hasGeoSearch && result?.source?.type === "arcgis-geocoder")
              ) {
                return;
              }

              availableResults.push({
                label: result.source.name,
                results: result.results,
                key: `${Math.random()}-${result.source.name}`,
                source: result.source,
              });
            });

            setShowNoResults(availableResults.length === 0);
            setShowCoordinates(false);
            setSuggestions(availableResults);
          } catch (err) {
            console.log(err);
          } finally {
            setLoading(false);
          }
        }
      }, 250);
    },
    [search, activeCoordinates]
  );

  const handleORSResultSelect = useCallback(
    async (feature) => {
      try {
        const { geometry } = feature || {};
        const { coordinates = [] } = geometry || {};
        if (coordinates?.length !== 2) {
          throw new Error("Invalid coordinates");
        }

        const [longitude, latitude] = coordinates;
        if (typeof longitude !== "number" || typeof latitude !== "number") {
          throw new Error("Coordinates must be numbers");
        }

        const point = {
          type: "point",
          longitude,
          latitude,
        };
        setSelectedResultKey(feature.properties.id);
        hideDropdown();

        await search.search(`${longitude}, ${latitude}`);
      } catch (error) {
        console.error("Error selecting ORS result:", error);
      }
    },
    [search, hideDropdown]
  );

  const handleSelectResult = async (result) => {
    const { feature, text, key, ...rest } = result;
    hideDropdown();

    try {
      const res = await search.search(result);

      if (res?.results?.length > 0) {
        const { results = [], source } = res.results[0] || {};

        if (results.length > 0) {
          const { feature, isLayerSearchSourceResult } = results[0];

          setActiveCoordinates({
            latitude: feature.geometry.latitude,
            longitude: feature.geometry.longitude,
          });

          if (feature) {
            clickEventOnFeature(feature, {
              zoom: false,
              duration: 1000,
              showPopup:
                !!source.layer?.layerConfig?.clickable &&
                isLayerSearchSourceResult,
            });
          }
        }
      }
    } catch (err) {
      console.log(err);
    }

    setSelectedResultKey(key);
  };

  /**
   * Handles the click event on a suggestion.
   *
   * @param {string} label - The label of the suggestion.
   * @param {Object} result - The result object of the suggestion.
   * @param {Object} result.feature - The feature object of the result (used when label includes "GeoSearch").
   */
  const handleSuggestionClick = useCallback(
    (label, result) => {
      if (label.includes("GeoSearch")) {
        handleORSResultSelect(result.feature);
      } else {
        handleSelectResult(result);
      }
    },
    [handleORSResultSelect, handleSelectResult]
  );

  const handleFocus = useCallback(() => {
    setShowCoordinates(true);
    setShowDropdown(true);
  }, []);

  const handleCoordinates = useCallback(
    (point) => {
      setActiveCoordinates({
        latitude: point.latitude,
        longitude: point.longitude,
      });

      let inputValue = ``;

      if (point.latitude) {
        inputValue += `${point.latitude}`;
      }

      if (point.longitude) {
        inputValue += `, ${point.longitude}`;
      }

      setValue(inputValue);
    },
    [search, hideDropdown]
  );

  const locateCoordinates = useCallback(async () => {
    try {
      if (
        activeCoordinates?.longitude == null ||
        activeCoordinates?.latitude == null
      ) {
        return;
      }

      const inputValue = `${activeCoordinates.latitude}, ${activeCoordinates.longitude}`;
      setValue(inputValue);
      const searchValue = `${activeCoordinates.longitude}, ${activeCoordinates.latitude}`;
      if (search) {
        hideDropdown();
        await search.search(searchValue);
      }
    } catch (err) {
      console.log(err);
    }
  }, [search, setValue, activeCoordinates, hideDropdown]);

  const handleBackClick = useCallback(() => {
    if (onBackClick) {
      onBackClick();
    }

    resetSearch();
  }, [resetSearch, onBackClick]);

  const handleKeyPress = useCallback(
    (event) => {
      if (event.key === "Enter") {
        event.preventDefault();
        locateCoordinates();
      }
    },
    [locateCoordinates]
  );

  const showSuggestions = useMemo(
    () => loading || showNoResults || showCoordinates || suggestions.length > 0,
    [showCoordinates, showNoResults, loading, suggestions]
  );

  return (
    <SearchBox
      value={value}
      onChange={handleInputChange}
      placeholder={t("screen.widget.Search.placeholder", "Search")}
      onFocus={handleFocus}
      onBackClick={handleBackClick}
      isMobileApp={isMobileApp}
      rotate={rotate}
      disabled={!search}
      onKeyPress={handleKeyPress}
    >
      <StyledSearchBox ref={ref}>
        <StyledSuggestions
          isMobileApp={isMobileApp}
          style={{
            display: showSuggestions ? "flex" : "none",
          }}
        >
          {loading && !showNoResults && <Loader position="absolute" />}
          <div style={{ flex: 1, overflow: "auto" }}>
            {showNoResults ? (
              <div>
                <StyledSuggestItem>No results found</StyledSuggestItem>
              </div>
            ) : (
              showDropdown &&
              (suggestions.length > 0 || showCoordinates) &&
              (!!suggestions.length ? (
                suggestions.map((suggestion) => (
                  <StyledSuggestGroup key={suggestion.key}>
                    <div>
                      {suggestion.results.map((result) => (
                        <StyledSuggestItem
                          key={result.key}
                          onClick={() =>
                            handleSuggestionClick(suggestion.label, result)
                          }
                          color="#ffffff"
                          selected={result.key === selectedResultKey}
                        >
                          {suggestion.source?.layer ? (
                            <LayerIcon
                              config={config}
                              layer={suggestion.source.layer}
                              t={t}
                              fill="#344054"
                              bgFill="#fff"
                              opsColor={
                                result.key === selectedResultKey
                                  ? "#9b9b9b"
                                  : "#fff"
                              }
                              width={20}
                              height={20}
                              selected={result.key === selectedResultKey}
                              textStyles={{
                                width: "24px",
                                display: "block",
                                height: "auto",
                                alignItems: "center",
                                justifyContent: "center",
                                fontSize: "18px",
                                textAlign: "center",
                                color:
                                  result.key === selectedResultKey
                                    ? "#fff"
                                    : "#344054",
                              }}
                            />
                          ) : (
                            <div
                              style={{
                                width: "20px",
                                height: "20px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <FaSearchLocation width="14px" height="14px" />
                            </div>
                          )}
                          <p>{result.text}</p>
                        </StyledSuggestItem>
                      ))}
                    </div>
                  </StyledSuggestGroup>
                ))
              ) : (
                <div
                  style={{
                    padding: "0px 4px 6px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <CoordinatesInputs
                    showAll={true}
                    showTitle={false}
                    addPointToNewFeature={handleCoordinates}
                    geometry={activeCoordinates}
                    color="#8c9ba5"
                    t={t}
                    onKeyPress={handleKeyPress}
                  />
                  <StyledLocateButton
                    disabled={
                      !activeCoordinates?.longitude ||
                      !activeCoordinates?.latitude
                    }
                    onClick={locateCoordinates}
                  >
                    <span>{t("screen.widget.Search.findThisLocation")}</span>
                    <FaSearchLocation fill="#000" />
                  </StyledLocateButton>
                </div>
              ))
            )}
          </div>
        </StyledSuggestions>
      </StyledSearchBox>
    </SearchBox>
  );
};

export default SearchWidget;
