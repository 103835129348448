import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDropzone } from "react-dropzone";
import {
  FileDelete,
  FilePreview,
  FilePreviewContainer,
  FileUpload,
  FileUploadText,
} from "./UploadFile-styled";
import { CircleClose } from "../../../Icons";
import Button from "../Button/Button";
import { download24 } from "@esri/calcite-ui-icons";
import {
  StyledSectionDescription,
  StyledSectionTitle,
} from "../ScrollSection/ScrollSection-styled";
import { getOpsColor } from "../../../../utils/helper";
import { useSelector } from "react-redux";

const UploadFile = ({
  form,
  setForm,
  name,
  config,
  title,
  description,
  buttonTitle,
  fileDescription,
  assignUrl = false,
  isMulti = true,
}) => {
  const file = useMemo(() => form[name], [form, name]);
  const [previews, setPreviews] = useState([]);
  const objectUrl = useRef([]);
  const { activeModule } = useSelector((state) => state);

  useEffect(() => {
    // create the preview
    // let objectUrl
    // if (file) {
    //   objectUrl = URL.createObjectURL(file)
    //   setPreview(objectUrl)
    // }

    // free memory when ever this component is unmounted
    return () => {
      objectUrl.current.forEach((url) => {
        URL.revokeObjectURL(url);
      });

      objectUrl.current = [];
      setPreviews([]);
    };
  }, []);

  const onDrop = useCallback(
    (acceptedFiles) => {
      // const acceptedFiles = isMulti ? files : [files];
      const previews = [];

      acceptedFiles.forEach((file) => {
        const url = URL.createObjectURL(file);
        previews.push(url);
      });

      objectUrl.current = previews;

      setPreviews(previews);

      if (assignUrl) {
        setForm(name, previews);
      } else {
        setForm(name, acceptedFiles);
      }

      // const file = acceptedFiles[0];

      // // setForm(form=>({...form, [name]: acceptedFiles[0]}))
      // if (file) {
      //   objectUrl.current = URL.createObjectURL(file);
      //   setPreview(objectUrl.current);

      // if (assignUrl) {
      //     setForm(name, objectUrl.current);
      //   } else {
      //     setForm(name, file);
      //   }
      // }
    },
    [setForm, assignUrl]
  );

  const handleDelete = (e, index) => {
    e.stopPropagation();

    const newPreviews = previews.filter((url, i) => {
      if (i === index) {
        URL.revokeObjectURL(url);
      }

      return i !== index;
    });

    setPreviews(newPreviews);

    const newFile = file.filter((_, i) => i !== index);

    setForm(name, newFile.length > 0 ? newFile : null);

    objectUrl.current = newPreviews;
  };

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop,
    noClick: true,
    noKeyboard: true,
    accept: "image/jpeg,image/jpg,image/png",
    multiple: isMulti,
  });

  const opsColor = useMemo(
    () => getOpsColor(config, activeModule),
    [config, activeModule]
  );

  return (
    <>
      {title && (
        <StyledSectionTitle style={{ margin: "5px 0px 0px 0px" }}>
          {title}
        </StyledSectionTitle>
      )}
      {description && (
        <StyledSectionDescription style={{ margin: 0 }}>
          {description}
        </StyledSectionDescription>
      )}
      <FileUpload
        color={opsColor}
        isDragActive={isDragActive}
        {...getRootProps()}
      >
        <input accept="image/*" {...getInputProps()} />
        {!file ? (
          <div>
            <Button onClick={open}>
              <svg
                width={18}
                height={18}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
              >
                <path d={download24} fill="#393838" />
              </svg>
              {!file && <span>{buttonTitle}</span>}
            </Button>
            <FileUploadText isDragActive={isDragActive}>
              {fileDescription}
            </FileUploadText>
          </div>
        ) : (
          previews.map((preview, index) => (
            <FilePreviewContainer key={preview}>
              <FilePreview src={preview} alt={file.name} />
              <FileDelete onClick={(e) => handleDelete(e, index)}>
                <CircleClose color={opsColor} />
              </FileDelete>
            </FilePreviewContainer>
          ))
        )}
      </FileUpload>
    </>
  );
};

export default UploadFile;
