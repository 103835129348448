import React, { useCallback, useMemo } from "react";
import { createSymbol, getLayerSymbology } from "../../utils/symbologies";
import { iconNameToIcon, svgToBase } from "./layerSvg/clusterAssets";
import { LayerIconWrapper } from "../ManualWidgets/ManualWidget-styled";
import { getLayerTitle } from "../../esri/custom-popup-content";

function removeStyleFromSvg(svgString) {
  const parser = new DOMParser();
  const svgDoc = parser.parseFromString(svgString, "image/svg+xml");
  const styleElement = svgDoc.querySelector("style");
  if (styleElement) {
    styleElement.remove();
  }
  return svgDoc.documentElement.outerHTML;
}

const LayerIcon = ({
  config,
  layer,
  t,
  fill,
  opsColor,
  width = 40,
  height = 40,
  selected,
  textColor = "#fff",
  textStyles = {},
}) => {
  const getLayerDefaultSymbol = useCallback(
    (layer) => {
      if (!layer) {
        return null;
      }

      const symbology = getLayerSymbology(layer, config);
      if (!symbology) {
        if (layer.renderer && layer.renderer.defaultSymbol) {
          return {
            type: "picture-marker",
            url: layer.renderer.defaultSymbol.url,
          };
        }
        return null;
      }

      const symbol = createSymbol(
        symbology,
        layer,
        symbology.defaultIcon,
        symbology.defaultColor,
        symbology.defaultSize
      );
      const iconName = symbology?.layerIcon || symbology?.defaultIcon || "";

      let icon;

      if (iconName) {
        const svgString = iconNameToIcon(iconName);

        if (svgString) {
          icon = removeStyleFromSvg(svgString);
        }
      }

      switch (symbol.type) {
        case "picture-marker":
          return { ...symbol, icon };
        case "simple-fill":
          return symbol;
        default:
          return {
            type: symbol.type,
            url: svgToBase(symbology.defaultIcon, symbology.defaultColor),
            icon,
          };
      }
    },
    [config, selected]
  );

  const symbol = useMemo(() => {
    return getLayerDefaultSymbol(layer);
  }, [layer, getLayerDefaultSymbol]);

  const layerTitle = useMemo(() => getLayerTitle(layer, t), [layer, t]);

  if (
    symbol &&
    symbol.type !== "simple-fill" &&
    symbol.icon &&
    symbol.width > 2 &&
    symbol.height > 2
  ) {
    return (
      <LayerIconWrapper
        width={width}
        height={height}
        opsColor={opsColor}
        fill={fill}
        selected={selected}
        dangerouslySetInnerHTML={{ __html: symbol.icon }}
      />
    );
  } else {
    return (
      <div
        style={{
          width: width - 10,
          height: height - 10,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: textColor,
          fontSize: 20,
          ...textStyles,
        }}
      >
        <span>{layerTitle?.length > 0 ? layerTitle[0] : ""}</span>
      </div>
    );
  }

  return symbol ? (
    <>
      {symbol.type === "simple-fill" && (
        <div
          style={{
            width: width - 10,
            height: height - 10,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: textColor,
            fontSize: 20,
            ...textStyles,
          }}
        >
          <span>{layerTitle.length > 0 ? layerTitle[0] : ""}</span>
        </div>
      )}
      {symbol.icon && symbol.type !== "simple-fill" && (
        <LayerIconWrapper
          width={width}
          height={height}
          opsColor={opsColor}
          fill={fill}
          selected={selected}
          dangerouslySetInnerHTML={{ __html: symbol.icon }}
        />
      )}
    </>
  ) : (
    <div
      style={{
        width: width - 10,
        height: height - 10,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: textColor,
        fontSize: 20,
        ...textStyles,
      }}
    >
      <span>{layerTitle?.length > 0 ? layerTitle[0] : ""}</span>
    </div>
  );
};

export default LayerIcon;
