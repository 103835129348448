export const setTypeOfVehicle_actionType = 'SET_TYPE_OF_VEHICLE';
export const setAdditionalComment_actionType = 'SET_ADDITIONAL_COMMENT';
export const setKnowledgeOfRehabilitation_actionType = 'SET_KNOWLEDGE_OF_REHABILITATION';
export const setGeometry_actionType = 'SET_GEOMETRY';
export const setSubCategory_actionType = 'SET_SUB_CATEGORY';
export const setNextPage_actionType = 'SET_NEXT_PAGE';
export const setPrevPage_actionType = 'SET_PREV_PAGE';
export const setResetToStartPage_actionType = 'SET_RESET_TO_START_PAGE';
export const removeData = 'REMOVE_DATA';
export const setFilters_actionType = 'SET_FILTERS';
export const setActiveModule_actionType = 'SET_ACTIVE_MODULE';
export const setPanelPosition_actionType = 'SET_PANEL_POSITION';
export const setPanelPath_actionType = 'SET_PANEL_PATH';
export const setPanelPageIndex_actionType = 'SET_PANEL_PAGE_INDEX';
export const setPanelFormData_actionType = 'SET_PANEL_FORM_DATA';
export const setPanelIsSurvey_actionType = 'SET_PANEL_IS_SURVEY';
export const setPanelSurveyIsValid_actionType = 'SET_PANEL_SURVEY_IS_VALID';
export const setPanelId_actionType = 'SET_PANEL_ID';
export const resetPanel = 'RESET_PANEL';
export const setClusterFeature_actionType = 'SET_CLUSTER_FEATURE';
export const setLayersLoading_actionType = 'SET_LAYERS_LOADING';
export const setPrintWidget_actionType = 'SET_PRINT_WIDGET';
export const setLatestUpdates_actionType = 'SET_LATEST_UPDATES';
export const setLegendWidgetUpdate_actionType = 'SET_LEGEND_WIDGET_UPDATE';
export const setAnimateFeatureTable_actionType = 'SET_ANIMATE_FEATURE_TABLE';
export const setEditableFeature_actionType = 'SET_EDITABLE_FEATURE';
export const setEditableLayer_actionType = 'SET_EDITABLE_LAYER';
export const setEditorDrawActive_actionType = 'SET_EDITOR_DRAW_ACTIVE';
export const setEditorAddPointActive_actionType = 'SET_EDITOR_ADD_POINT_ACTIVE';

export const setMobileApp_actionType = 'SET_APP_EMBED';
export const setMobileActiveList_actionType = 'SET_MOBILE_ACTIVE_LIST';
export const setPrintWidgetOpen_actionType = 'SET_PRINT_WIDGET_OPEN';
export const setPrintOption_actionType = "SET_PRINT_PRINT_OPTION";
export const setPrintWidgetStep_actionType = 'SET_PRINT_WIDGET_STEP';
export const setPrintWidgetLoading_actionType = 'SET_PRINT_WIDGET_LOADING';
export const setMapView_actionType = 'SET_MAP_VIEW';

export const setCycleManagerData_actionType = 'SET_CYCLE_MANAGER_DATA';
export const setCycleManagerFeatures_actionType = 'SET_CYCLE_MANAGER_FEATURES';
export const setLayersUpToDate_actionType = 'SET_LAYERS_UP_TO_DATE';
export const setLayersUpdates_actionType = 'SET_LAYERS_UPDATES';
export const addLayersUpdates_actionType = 'ADD_LAYERS_UPDATES';
export const setCycleManagerOpen_actionType = 'SET_CYCLE_MANAGER_OPEN';
