/**
 * Focuses on the input element inside a search box.
 * Retries every 50 milliseconds if the input is not available or is disabled.
 */
export const focusSearchInput = () => {
  setTimeout(() => {
    const searchBox = document.querySelector(".search__box");
    if (searchBox) {
      const input = searchBox.querySelector("input");

      if (input) {
        const focusInput = () => {
          if (input && !input.disabled) {
            input.focus();
          } else {
            setTimeout(focusInput, 50); // Retry every 100 ms
          }
        };

        focusInput();
      }
    }
  }, 100);
};
