import React, { useCallback, useContext, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setPrintOption,
  setPrintWidgetOpen,
} from "../../../redux/action/PrintWidget-action";
import WidgetButton from "../../Header/components/WidgetButton";
import { ConfigContext } from "../../../utils/ConfigContext";
import { useTranslation } from "react-i18next";
import {
  ProgressLoader,
  StyledProgressBar,
} from "../../Report/new/ProgressBar/ProgressBar-styled";
import { PrintLoadingOverlay } from "./PrintButton-styled";
import { stepsValues, transition } from "../SidebarMenu/SidebarMenu";
import { getOpsColor, ROLE_EDITOR } from "../../../utils/helper";
import Dropdown, { DropdownMenu, DropdownMenuItem } from "../../Dropdown";
import { BSBrush, BSLightning } from "../../Icons";
import { PRINT_OPTIONS } from "../../../redux/reducers/printWidget";
import Tooltip from "../../UI/Tooltip";

const PrintButton = () => {
  const [show, setShow] = useState(false);
  const { config } = useContext(ConfigContext);
  const { t } = useTranslation("common");
  const { printWidget, activeModule } = useSelector((state) => state);
  const dispatch = useDispatch();

  const opsColor = useMemo(
    () => getOpsColor(config, activeModule),
    [config, activeModule]
  );

  const allowPublicCustomization = useMemo(
    () =>
      config?.printMap?.allowPublicPrintCustomisations &&
      config.role !== ROLE_EDITOR,
    [config]
  );

  const openPrintWidget = useCallback(() => {
    if (printWidget.isLoading) return;
    dispatch(setPrintWidgetOpen(true));
  }, [dispatch, printWidget.isLoading]);

  const handlePrintOptionSelect = useCallback(
    (type) => {
      dispatch(setPrintOption(type));
      openPrintWidget();
      setShow(false);
    },
    [dispatch, openPrintWidget]
  );

  const content = (
    <WidgetButton
      data-tag-name="Print-Start"
      title={t("screen.widget.Print.name")}
      type="Print"
      className="onboarding-print gtag"
      isLoading={printWidget.isLoading}
      disabled={printWidget.isLoading}
      onClick={!allowPublicCustomization ? openPrintWidget : undefined}
    >
      <PrintLoadingOverlay isLoading={printWidget.isLoading}>
        <div
          style={{
            width: "100%",
            height: 10,
            minHeight: 10,
            borderRadius: 10,
            overflow: "hidden",
            background: "#a8b1b780",
            position: "relative",
          }}
        >
          <ProgressLoader color={opsColor} />
          <StyledProgressBar
            style={{ transition: `all ${transition[printWidget.step]}s` }}
            color={opsColor}
            progress={(100 / 4) * (stepsValues[printWidget.step] ?? 0)}
          />
        </div>
        <span>{t("print.final.loadingSteps." + printWidget.step)}</span>
      </PrintLoadingOverlay>
    </WidgetButton>
  );

  if (!allowPublicCustomization) {
    return content;
  }

  return (
    <Dropdown
      show={show}
      setShow={!printWidget.isLoading ? setShow : undefined}
      centerTooltip
    >
      {content}
      <DropdownMenu style={{ minWidth: 80 }}>
        <Tooltip
          showOnHover
          placement="left"
          content={t("print.dropdown.quick.tooltip")}
        >
          <DropdownMenuItem
            opsColor={opsColor}
            onClick={() => handlePrintOptionSelect(PRINT_OPTIONS.quick)}
          >
            <BSLightning width="14" height="14" />
            <span>{t("print.dropdown.quick.title")}</span>
          </DropdownMenuItem>
        </Tooltip>

        <Tooltip
          showOnHover
          placement="left"
          content={t("print.dropdown.custom.tooltip")}
        >
          <DropdownMenuItem
            opsColor={opsColor}
            onClick={() => handlePrintOptionSelect(PRINT_OPTIONS.custom)}
          >
            <BSBrush width="14" height="14" />
            <span>{t("print.dropdown.custom.title")}</span>
          </DropdownMenuItem>
        </Tooltip>
      </DropdownMenu>
    </Dropdown>
  );
};

export default PrintButton;
