import styled from "styled-components";

export const ManualWidgetWrapper = styled.div`
  width: auto;
  display: flex;
  align-items: flex-end;

  .swiper {
    padding: 0 10px;
    height: 51px;
  }

  .swiper-wrapper {
    display: flex;
    align-items: flex-end;
  }

  .swiper-slide {
    width: auto;
    height: auto;
    padding-bottom: 4px;
    pointer-events: auto;
  }

  @media screen and (max-width: 768px) {
    //width: 100%;
  }
`;

export const StyledToggleWrapper = styled.div`
  width: fit-content;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyledToggleTitle = styled.div`
  width: fit-content;
  background: #fff;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  padding: 2px 6px;
  opacity: 0.9;

  p {
    margin: 0;
    line-height: normal;
    font-size: 10px;
    color: #344054;
  }
`;

export const StyledToggleButtonWrapper = styled.div`
  position: relative;
  display: flex;
  background: #ffffff;
  border-radius: 8px;
  //border: 1px solid #D0D5DD;
  border: none;
  transition: all 0.5s;
  overflow: hidden;

  box-shadow: 0 1px 2px rgba(60, 64, 67, 0.3),
    0 1px 3px 1px rgba(60, 64, 67, 0.15);
  &:hover {
    box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.2);
  }
`;

export const StyledToggleButton = styled.button`
  border: none;
  background: transparent;
  padding: 4px 8px;
  cursor: pointer;
  background: ${({ selected, opsColor }) =>
    selected ? opsColor ?? "#D1EDFF" : "#fff"};
  color: ${({ selected, opsColor }) =>
    selected && opsColor ? "#fff" : "#344054"};
  border-radius: ${({ radius }) => radius};
  min-height: 28px;

  &:not(:first-child) {
    border-left: 1px solid #d0d5dd;
  }

  & span {
    font-size: 12px;
    position: relative;
    z-index: 1;
    pointer-events: none;
    line-height: 1;
  }
`;
export const FilterDropdownWrapper = styled.div`
  position: relative;
  border-radius: 8px;
  display: flex;
  align-items: flex-end;
`;

export const MapFilterButton = styled.button`
  padding: 5px 8px;
  cursor: pointer;
  background: #fff;
  border-radius: 8px;
  //border: 1px solid #D0D5DD;
  border: none;
  transition: all 0.5s;
  box-shadow: 0 1px 2px rgba(60, 64, 67, 0.3),
    0 1px 3px 1px rgba(60, 64, 67, 0.15);
  display: flex;
  align-items: center;
  background: ${({ selected, opsColor }) =>
    selected ? opsColor ?? "#D1EDFF" : "#fff"};
  color: ${({ selected, opsColor }) =>
    selected && opsColor ? "#fff" : "#344054"};
  min-height: 28px;

  &:hover {
    box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.2);
  }

  span {
    font-size: 12px;
    line-height: 1;
  }

  @media screen and (max-width: 768px) {
    padding: 6px 10px !important;
    border-radius: 100px;
    min-height: 34px;

    span {
      font-size: 14px;
    }
  }

  svg path {
    fill: #344054;
  }
`;

export const LayerIconWrapper = styled.div`
  svg {
    display: block;
    width: ${({ width }) => (width ? `${width}px` : "22px")} !important;
    height: ${({ height }) => (height ? `${height}px` : "22px")} !important;
    transition: all 0.5s;

    rect:not(.bg0),
    circle,
    .cls-2 {
      transition: all 0.5s;
      fill: ${({ selected, opsColor }) =>
        selected ? opsColor : "#fff"} !important;
    }
  }

  .st0 {
    fill: ${({ selected, opsColor }) =>
      selected ? opsColor : "#fff"} !important;
    transition: all 0.5s;
  }

  .bg0,
  .st1 {
    fill: ${({ selected, fill }) =>
      selected ? "#ffffff" : fill ? fill : "#344054"} !important;
    fill-opacity: 1;
    transition: all 0.5s;
  }
  //
  .cls-1 {
    //main
    fill: ${({ selected, fill }) =>
      selected ? "#fff" : fill ? fill : "#ffffff"} !important;
    transition: all 0.5s;
  }
`;

export const FilterDropdownMenu = styled.div`
  //opacity: ${({ show }) => (show ? 1 : 0)};
  // pointer-events: ${({ show }) => (show ? "all" : "none")};
  max-height: 374px;
  overflow: auto;
  width: 300px;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-shadow: 0 1px 2px rgba(60, 64, 67, 0.3),
    0 1px 3px 1px rgba(60, 64, 67, 0.15);
  border-radius: 8px;
  padding-top: 4px;
  transition: all 0.4s;
  z-index: 2;

  @media screen and (max-width: 768px) {
    max-width: 200px;
  }
`;

export const FilterDropdownMenuOption = styled.div`
  width: 100%;
  transition: all 0.2s;
  color: #344054;

  &:hover {
    background: ${({ opsColor }) => opsColor ?? "#D1EDFF"};
    color: #ffffff;
  }

  label {
    padding: 6px 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 4px;
    user-select: none;

    span {
      font-size: 12px;
      line-height: normal;
      text-align: left;
    }
  }

  input {
    margin: 0 5px 0 0;
    scale: 1.2;
  }
`;

export const FilterDropdownFooter = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  background: inherit;
  position: sticky;
  bottom: 0;
  padding: 4px 0;

  button {
    background: inherit;
    border: none;
    padding: 4px;
    margin-right: 8px;
    cursor: pointer;
    font-size: 12px;
    font-weight: 500;
    transition: all 0.3s;
    color: #344054;

    :hover {
      color: #1a73e8;
    }
  }
`;

export const StyledLoaderWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  background: #ffffff4d;

  calcite-loader {
    stroke: var(--opsColor) !important;
    --calcite-loader-size: 1.5rem;
  }
`;
