import React, {
  lazy,
  Suspense,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import ModuleIcon from "../../Modules/ModuleIcon";
import Modules from "../../Modules";
import {
  StyledMobileButton,
  StyledMobileHeader,
  StyledMobileHeaderTitle,
} from "../Header-styled";
import ScreenDropdown from "./ScreenDropdown";
import { useTranslation } from "react-i18next";
import { ConfigContext } from "../../../utils/ConfigContext";
import MobileSearch from "./MobileSearch";
import { LogIEIconSmall } from "../../Icons/Header-Icons";
import {
  EMBED_VARIANTS,
  getOperationPreferences,
  getOpsColor,
  getVisibleFilters,
} from "../../../utils/helper";
import { isWidgetDisplayed } from "../../../esri/widgets/expandUtils";
import { MOBILE_SCREENS } from "../../App/App";
import { setActiveList } from "../../../redux/action/MobileReducer-action";
import { MOBILE_LISTS } from "../../../redux/reducers/mobileReducer";
// import { view } from "../../../utils/API";
import useOnboarding from "../../../hooks/useOnboarding";

const Filters = lazy(() => import("../../Widgets/Filters"));
import mapViewReducer from "../../../redux/reducers/mapViewReducer";

const MobileHeader = ({
  setShowModules,
  showModules,
  tour,
  setMobileScreen,
  mobileScreen,
  setShowFilters,
}) => {
  const {
    activeModule,
    showLatestUpdates,
    mobileReducer,
    mapViewReducer: { view },
  } = useSelector((state) => state);
  const { t } = useTranslation("common");
  const { config } = useContext(ConfigContext);
  const [expandSearch, setExpandSearch] = useState(false);
  const { onBoardingTour } = useOnboarding();
  const dispatch = useDispatch();

  useEffect(() => {
    const languageConfig = JSON.parse(localStorage.getItem("languageConfig"));
    if (languageConfig) {
      const { showModules } = languageConfig;
      setShowModules(showModules);
      localStorage.removeItem("languageConfig");
    }
  }, []);

  useEffect(() => {
    if (!config) return;
    const { allPreferences: preferences, operationPreferences } =
      getOperationPreferences(config.id);

    if (
      !operationPreferences.activeModule &&
      Array.isArray(config.activeModules) &&
      config.activeModules.length > 1 &&
      config.embed !== EMBED_VARIANTS.WEBSITE
    ) {
      const searchParams = new URLSearchParams(window.location.search);
      const urlModule = searchParams.get("module");
      const dataCollection = searchParams.get("datacollection");
      if (urlModule || dataCollection) return;

      setShowModules(true);
    }
  }, [config]);

  useEffect(() => {
    if (expandSearch) {
      setExpandSearch(false);
    }
  }, [mobileScreen]);

  const isHeaderShown = useMemo(
    () =>
      !(
        config.embed === EMBED_VARIANTS.APP ||
        config.embed === EMBED_VARIANTS.WEBSITE
      ),
    [config]
  );

  const title = t("ops." + config.id + ".title", config.title) || "";

  const isSearchWidgetDisplayed = useMemo(() => {
    return (
      isWidgetDisplayed(config, "Search") &&
      mobileScreen !== MOBILE_SCREENS.INSIGHTS
    );
  }, [config, mobileScreen]);

  const latestUpdatesSelected = useMemo(() => {
    return mobileReducer.activeList === MOBILE_LISTS.LATEST_UPDATES;
  }, [mobileReducer.activeList]);

  const refInit = useCallback((el) => {
    if (!el || !window) return;

    const { width } = el.parentNode.getBoundingClientRect() || {};
    const { width: elementWidth } = el.getBoundingClientRect() || {};
    if (width !== undefined && elementWidth !== undefined) {
      const availableWidth = width - 16 - 32;

      if (availableWidth < elementWidth) {
        el.style.paddingLeft = "42px";
      } else {
        el.style.paddingLeft = undefined;
      }
    }
  }, []);

  const opsColor = useMemo(() => getOpsColor(config), [config, activeModule]);

  return (
    <>
      <Modules
        tour={tour}
        config={config}
        show={showModules}
        setShow={setShowModules}
        setMobileScreen={setMobileScreen}
        isHeaderShown={isHeaderShown}
        onBoardingTour={onBoardingTour}
      />
      {isHeaderShown && (
        <StyledMobileHeaderTitle>
          <a href="/" style={{ display: "flex", position: "absolute" }}>
            <LogIEIconSmall fill="#fff" />
          </a>
          <span ref={refInit}>{title}</span>
        </StyledMobileHeaderTitle>
      )}
      <StyledMobileHeader
        isHeaderShown={isHeaderShown}
        expanded={expandSearch}
        isSearchWidgetDisplayed={isSearchWidgetDisplayed}
      >
        <div className="btns" style={{ marginRight: "auto" }}>
          {activeModule && (
            <ModuleIcon
              withShadow
              module={activeModule}
              onClick={() => {
                setShowModules(true);
              }}
              opsColor={opsColor}
              className="onboarding-modules"
            />
          )}
          <ScreenDropdown
            setMobileScreen={setMobileScreen}
            mobileScreen={mobileScreen}
            config={config}
            t={t}
          />
        </div>
        <div className="btns">
          {showLatestUpdates && mobileScreen === MOBILE_SCREENS.TABLE && (
            <StyledMobileButton
              onClick={() => {
                if (latestUpdatesSelected) {
                  dispatch(setActiveList(MOBILE_LISTS.LAYERS));
                } else {
                  dispatch(setActiveList(MOBILE_LISTS.LATEST_UPDATES));
                }
              }}
              className="lu__btn"
              style={{
                border: `3px solid ${
                  latestUpdatesSelected ? opsColor : "#eee"
                }`,
                borderRadius: 100,
              }}
            >
              <span>{t("screen.widget.LatestUpdates.title")}</span>
            </StyledMobileButton>
          )}
          <Suspense>
            <Filters isMobile={true} className="onboarding-mobile-filter" />
          </Suspense>
          {/*{hasFilters && (*/}
          {/*  <StyledMobileButton*/}
          {/*    onClick={() => setShowFilters(true)}*/}
          {/*    className="onboarding-mobile-filter"*/}
          {/*  >*/}
          {/*    <FilterIcon/>*/}
          {/*    <MobileFilter*/}
          {/*      showFilters={true}*/}
          {/*      setShowFilters={setShowFilters}*/}
          {/*      isMobile={true}*/}
          {/*    />*/}
          {/*  </StyledMobileButton>*/}
          {/*)}*/}
        </div>
        {isSearchWidgetDisplayed && view && (
          <MobileSearch
            t={t}
            mobileScreen={mobileScreen}
            expandSearch={expandSearch}
            setExpandSearch={setExpandSearch}
          />
        )}
      </StyledMobileHeader>
    </>
  );
};

export default MobileHeader;
