import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
  useTransition,
} from "react";
import { useSelector } from "react-redux";
import useCustomSnackbar from "../../../hooks/useCustomSnackbar";
import getAllowedFields from "../../CustomPopupWrapper/helpers/getAllowedFields";
import { copySharableURL, view } from "../../../utils/API";
import { createSymbol, getLayerSymbology } from "../../../utils/symbologies";
import {
  clickEventOnFeature,
  EMBED_VARIANTS,
  renderCell,
  standardizeColor,
  timeAgo,
  toggleEditorWidget,
} from "../../../utils/helper";
import { TABS } from "../../MobileApp/MobilePopup/FeatureDetailsTab/FeatureDetailsTab";
import getPopupActions from "../../CustomPopupWrapper/helpers/getPopupActions";
import { useTranslation } from "react-i18next";
import { ConfigContext } from "../../../utils/ConfigContext";
import { getLayerConfigFields } from "../../MobileApp/MobilePopup/PopupBody/PopupBody";
import { StyledField } from "../../MobileApp/MobilePopup/MobilePopup-styled";
import FeatureHeading from "../../MobileApp/MobilePopup/PopupBody/FeatureHeading/FeatureHeading";
import {
  Card,
  CardDeck,
  FeatureImageSwiperWrapper,
  ImageCountTag,
  NavigationButtons,
  NavigationButtonsWrapper,
  ReportCardWrapper,
  ReportImage,
  ReportImagesWrapper,
  Tag,
} from "../SharedInformationCard/SharedInformationCard-styled";
import PopupField from "../../MobileApp/MobilePopup/PopupBody/FieldsSection/PopupField";
import useFeature from "../../../hooks/useFeature";
import { FeatureTitle } from "../../MobileApp/MobilePopup/PopupBody/FeatureHeading/FeatureHeading-styled";
import { getFeatureIcon } from "../../Dashboard/Editor/EditorFields/EditorFields";
import ReactDOMServer from "react-dom/server";
import { LineSymbol } from "../../ReportManager/symbols";
import Tooltip from "../../UI/Tooltip";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  EffectCards,
  EffectCoverflow,
  Navigation,
  Pagination,
} from "swiper/modules";
import useClickOutside from "../../../hooks/useClickOutside";
import ImageSwiper from "../../MobileApp/MobilePopup/PopupBody/ImageSwiper/ImageSwiper";
import { CalciteSwitch } from "@esri/calcite-components-react";

const currSourceFieldName = "currsourcename";
const currInfoRelyFieldName = "currinforely";
const currAsOfDateFieldName = "asofdate";

const updatesInfoFields = [
  currAsOfDateFieldName,
  currSourceFieldName,
  currInfoRelyFieldName,
];

const rotations = [0, -5, 5];

const ReportBody = ({ config, layer, selectedFeature, t, i18n, onClick }) => {
  const allowedFields = useMemo(() => {
    return getAllowedFields(layer, selectedFeature);
  }, [layer, selectedFeature]);

  const getFields = (filterFields) => {
    if (!Array.isArray(filterFields)) return null;

    return allowedFields.filter(
      (field) => filterFields.includes(field.name) && !!field
    );
  };

  const symbologyFields = useMemo(() => {
    const layer = selectedFeature.sourceLayer ?? selectedFeature.layer;
    const result = [];
    if (layer) {
      const symboloy = getLayerSymbology(layer, config);
      const { colorMap } = symboloy || {};

      if (colorMap) {
        const colorMapFields = colorMap.field
          ? [colorMap.field]
          : colorMap.fields;
        const layerFields = layer.fields.filter((field) =>
          colorMapFields.includes(field.name)
        );

        const colors = layerFields.map((field) => {
          const color =
            colorMap.default[selectedFeature.attributes[field.name]] ??
            symboloy.defaultColor;
          const standartColor = standardizeColor(color);
          if (color)
            return {
              value: renderCell(
                field,
                selectedFeature.attributes[field.name],
                t,
                config
              ),
              bg: standartColor.includes("#ffffff") ? "#000" : standartColor,
              color: "#fff",
              field: field.name,
              title: t("layer.fieldAlias." + field.name, field.alias),
            };
        });
        result.push(...colors);
      }
    }
    return result;
  }, [selectedFeature, config, t]);

  const renderedFields = useMemo(() => {
    const fields = [];
    if (Array.isArray(layer?.layerConfig.situationalFields)) {
      fields.push(...layer?.layerConfig.situationalFields);
    }

    if (Array.isArray(layer.layerConfig.baselineFields)) {
      fields.push(...layer?.layerConfig.baselineFields);
    }

    const fieldsToShow = getLayerConfigFields({
      layer,
      layerConfigFields: fields,
      config,
      t,
      feature: selectedFeature,
      symbologyFields,
    });

    const first = fieldsToShow.slice(0, symbologyFields.length > 0 ? 1 : 2);
    const more = fieldsToShow.slice(2);

    return {
      first,
      more,
    };
  }, [selectedFeature, config, t, layer, symbologyFields]);

  const format = (value) => {
    if (!value) return;
    return timeAgo(new Date(value), i18n.language, false);
  };

  const statusColor = useMemo(() => {
    if (symbologyFields.length > 0) {
      return standardizeColor(symbologyFields[0].bg);
    }

    return "#a6a6a6";
  }, [symbologyFields]);
  const isAppEmbed = useMemo(
    () => config.embed === EMBED_VARIANTS.APP,
    [config]
  );

  const isArabic = useMemo(() => {
    return i18n.language === "ar";
  }, [i18n.language]);

  const isPopup = true;
  const expand = true;

  return (
    <>
      <div
        style={{
          marginTop: 8,
          padding: "0px 8px 4px",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column", gap: 2 }}>
          {symbologyFields.map((field) => (
            <StyledField
              key={field.title}
              isPopup={isPopup}
              dir={isArabic ? "rtl" : "ltr"}
            >
              <p>
                <span>
                  {field.title + ": "}{" "}
                  <span
                    style={{ color: field.bg, fontWeight: 600 }}
                    dangerouslySetInnerHTML={{ __html: field.value }}
                  />
                </span>
              </p>
            </StyledField>
          ))}

          {[...renderedFields.first, ...renderedFields.more].map((field) => (
            <PopupField
              key={field.title}
              title={field.title}
              value={field.value}
              resetLimit={!expand}
              isPopup={isPopup}
              i18n={i18n}
              feature={selectedFeature}
              field={field}
              t={t}
              isExpanded={expand}
            />
          ))}
        </div>
      </div>
    </>
  );
};

const ReportsIndicators = ({ isArabic, groupedReports }) => {
  return (
    <div
      style={{
        display: "flex",
        gap: 4,
        padding: "8px 8px",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {Object.keys(groupedReports).map((k) => (
        <Tag
          dir={isArabic ? "rtl" : "ltr"}
          key={k}
          style={{
            color: "#fff", //groupedReports[k].color,
            // border:`1px solid ${groupedReports[k].color}`,
            padding: "4px 8px",
            display: "flex",
            alignItems: "center",
            background: groupedReports[k].color,
          }}
        >
          <span style={{ fontWeight: 400 }}>
            {groupedReports[k].count}&nbsp;
          </span>
          <span style={{ fontWeight: 400 }}>{groupedReports[k].title}</span>
        </Tag>
      ))}
    </div>
  );
};

const ReportCard = ({ selectedFeature, onClick }) => {
  const { i18n, t } = useTranslation("common");
  const { config } = useContext(ConfigContext);
  const { dateTimeAgo, getImage, title } = useFeature(selectedFeature, t);
  const [showImageSwiper, setShowImageSwiper] = useState(false);
  const ref = useRef();
  useClickOutside(ref, () => {
    setShowImageSwiper(false);
  });

  const layer = selectedFeature.sourceLayer
    ? selectedFeature.sourceLayer
    : selectedFeature.layer;

  const fields = layer.fields;

  const [images, setImages] = useState([]);
  const [attachments, setAttachments] = useState([]);
  const [openSnackbar] = useCustomSnackbar();
  const [isPending, startTransition] = useTransition();

  useEffect(() => {
    setImages([]);
    if (!layer.capabilities.operations.supportsQueryAttachments) return;

    layer
      .queryAttachments({
        objectIds: [selectedFeature.attributes[layer.objectIdField]],
      })
      .then((response) => {
        const attachments = Object.values(response).flat(1);

        startTransition(() => {
          setImages(
            attachments?.filter((att) => att.contentType.startsWith("image")) ||
              []
          );
          setAttachments(
            attachments?.filter(
              (att) => !att.contentType.startsWith("image")
            ) || []
          );
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }, [selectedFeature, fields, layer]);

  const reportsCount = useMemo(() => {
    if (
      Array.isArray(selectedFeature.reports) &&
      selectedFeature.reports.length > 0
    ) {
      return selectedFeature.reports.length;
    }

    return 0;
  }, [selectedFeature]);
  const actionButtons = useMemo(
    () => getPopupActions({ t, layer, config }),
    [t, layer, config]
  );

  const handleEdit = (e, editIndex) => {
    e.stopPropagation();
    toggleEditorWidget(false);

    if (editIndex > -1) {
      clickEventOnFeature(selectedFeature, {
        zoom: false,
        duration: 1,
        showPopup: true,
      }).then((res) => {
        view.popup.visible = false;
        setTimeout(() => {
          view.popup.triggerAction(editIndex);
        }, 100);
      });
    }
  };

  const image = useMemo(() => getImage(), [selectedFeature]);
  const isArabic = useMemo(() => {
    return i18n.language === "ar";
  }, []);

  const editFieldName = useMemo(() => {
    const layer = selectedFeature.layer;
    if (
      layer.fields.some((f) => f.name === currAsOfDateFieldName) &&
      selectedFeature.attributes[currAsOfDateFieldName]
    ) {
      return currAsOfDateFieldName;
    }

    return layer?.editFieldsInfo?.editDateField;
  }, [selectedFeature]);

  const format = (value) => {
    return timeAgo(new Date(value), i18n.language, false);
  };

  const symbology = useMemo(() => {
    const layer = selectedFeature.sourceLayer || selectedFeature.layer;
    const symbology = getLayerSymbology(layer, config);
    if (!symbology) return;

    const iconName = getFeatureIcon(selectedFeature, symbology);
    const newSymbol = createSymbol(
      symbology,
      layer,
      iconName,
      symbology.defaultColor,
      symbology.defaultSize
    );

    if (newSymbol.url) return newSymbol.url;
    else if (newSymbol.type === "simple-line" && newSymbol.color) {
      const color = newSymbol.color ? newSymbol.color : "grey";
      return (
        "data:image/svg+xml;base64," +
        btoa(ReactDOMServer.renderToStaticMarkup(<LineSymbol stroke={color} />))
      );
    }
  }, [selectedFeature]);

  const groupedReports = useMemo(() => {
    if (!selectedFeature.reports) return null;

    const symbologyLayer =
      selectedFeature.reports[0].sourceLayer ||
      selectedFeature.reports[0].layer;
    const symbology = getLayerSymbology(symbologyLayer, config);
    const colorMap = symbology.colorMap?.default || {};

    const reportsMap = {};
    selectedFeature.reports.forEach((report) => {
      const value = report.attributes.currvalidationfield;

      if (reportsMap[value]) {
        reportsMap[value].count++;
      } else {
        reportsMap[value] = {
          color: colorMap[value] || symbology.defaultColor,
          count: 1,
          title: t("layer.domain.currvalidationfield." + value, `${value}`),
        };
      }
    });

    return reportsMap;
  }, [selectedFeature, t, config]);

  const swiperRef = useRef();

  return (
    <ReportCardWrapper onClick={onClick}>
      {selectedFeature.reports ? (
        <div
          style={{
            padding: "8px 8px 0px 8px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div style={{ display: "flex", gap: 4, flex: 1, overflow: "hidden" }}>
            {symbology && (
              <div
                style={{
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  width: 36,
                  minWidth: 30,
                  position: "relative",
                }}
              >
                <img
                  style={{
                    maxWidth: "100%",
                    height: "auto",
                  }}
                  alt="symbol-url"
                  src={symbology}
                />
              </div>
            )}
            <div
              style={{
                flex: 1,
                overflow: "hidden",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <FeatureTitle
                style={{
                  width: "100%",
                }}
                size="md"
                isPopup={true}
              >
                <p className="text-elipsis">{title}</p>
              </FeatureTitle>
              <span
                style={{
                  fontSize: 12,
                  color: "#000",
                  fontWeight: 400,
                }}
              >
                {t("screen.widget.ReportManager.clickToSeeReports")}
              </span>
            </div>
          </div>
          {selectedFeature.reports &&
            Object.keys(groupedReports).length <= 2 && (
              <ReportsIndicators
                feature={selectedFeature}
                config={config}
                t={t}
                groupedReports={groupedReports}
                isArabic={isArabic}
              />
            )}
        </div>
      ) : (
        <div style={{ padding: "8px 8px 0px 8px" }}>
          <FeatureHeading
            feature={selectedFeature}
            t={t}
            actionButtons={
              reportsCount === 0
                ? [actionButtons.find((action) => action.id === "edit-action")]
                : []
            }
            expanded={true}
            isPopup={true}
            hasClose={false}
            onEdit={handleEdit}
            size="sm"
            defaultColor
          />
          {images.length > 0 && <ImageSwiper images={images} />}
        </div>
      )}
      {!selectedFeature.reports ? (
        <ReportBody
          selectedFeature={selectedFeature}
          layer={layer}
          config={config}
          t={t}
          i18n={i18n}
          onClick={onClick}
        />
      ) : Object.keys(groupedReports).length > 2 ? (
        <ReportsIndicators
          feature={selectedFeature}
          config={config}
          t={t}
          isArabic={isArabic}
          groupedReports={groupedReports}
        />
      ) : null}

      {/* {images.length > 0 && (
        <ReportImagesWrapper>
          <Dropdown
            show={showImageSwiper}
            setShow={setShowImageSwiper}
            hasMinWidth={false}
          >
            <div>
              <CardDeck
              // onClick={(e) => {
              //   e.stopPropagation();
              //   setShowImageSwiper(true);
              // }}
              >
                <ImageCountTag>{images.length}</ImageCountTag>
                {[
                  ...images,
                  // ...images,
                  // ...images,
                  // ...images,
                  // ...images,
                  // ...images,
                  // ...images,
                ].map((image, index) => (
                  <Card
                    key={image.id}
                    rotation={rotations[index % rotations.length]}
                  >
                    <img src={image.url} alt={`Image ${image.id}`} />
                  </Card>
                ))}
              </CardDeck>
            </div>
            <DropdownBody>
              <ReportImage
                slideImage={true}
                style={{
                  width: 300,
                  height: 300,
                  overflow: "hidden",
                }}
              >
                <Swiper
                  // pagination={{
                  //   type: "fraction",
                  // }}
                  navigation={true}
                  modules={[Navigation]}
                  slidesPerView="auto"

                  // effect={"coverflow"}
                  // grabCursor={true}
                  // centeredSlides={true}
                  // slidesPerView={"auto"}
                  // coverflowEffect={{
                  //   rotate: 50,
                  //   stretch: 0,
                  //   depth: 100,
                  //   modifier: 1,
                  //   slideShadows: true,
                  // }}
                  // pagination={true}
                  // modules={[EffectCoverflow, Pagination]}
                >
                  {images.map((image) => (
                    <SwiperSlide key={image.id}>
                      <img src={image.url} />
                    </SwiperSlide>
                  ))}
                </Swiper>
              </ReportImage>
            </DropdownBody>
          </Dropdown>
        </ReportImagesWrapper>
      )} */}
    </ReportCardWrapper>
  );
};

export default ReportCard;
